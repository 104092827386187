@use "../../../styles/variables.module" as *;
@use "../../../styles/zIndex.module" as zIndex;

.container {
  padding: 1rem;
  background: white;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: zIndex.$z-index-modal;
  width: 98vw;
  margin: 1vw;
  color: var(--blockBgTextColorNoBlocksUseSecondary);
  outline: 1.5px solid var(--blockBgTextColorNoBlocksUseSecondary);

  &IosSafariPosition {
    bottom: 0;
    top: unset;
    z-index: zIndex.$z-index-modal;
  }

  .installButton {
    padding-top: 5px;
    text-align: center;
    button {
      border: 2px solid var(--blockBgTextColorNoBlocksUseSecondary);
      padding: 6px 18px;
      border-radius: 20px;
      background: white;
      border: 2px solid $admin-blue-dark;
      font-size: 1.3rem;
    }
  }

  > button {
    // close button
    position: absolute;
    top: 5px;
    right: 5px;
  }

  > div {
    vertical-align: top;

    h2 {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }

  &Push {
    justify-content: space-around;
    padding: 3rem 2rem 2rem 2rem;
    background: #e9e9e9;
    color: black;
    button {
      padding: 6px 18px;
      border-radius: 20px;
      background: white;
      border: 2px solid $admin-blue-dark;
      font-size: 1.3rem;
    }
  }
}

.icon {
  padding-right: 20px;

  img {
    width: 90px;
  }
}
