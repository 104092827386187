@use "../../styles/variables.module" as *;

.settingsContainer {
  background: white;
  border-radius: 20px;
  padding: 1rem;
  color: $black;
}

 .heading {
  margin: 20px 0px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 35vw;
  max-width: 400px;
  border-collapse: collapse;
}
.editable {
  height: 26px;
  min-width: 50px;
}

.itemRow {
  display: flex;
  font-weight: 600;
  flex-direction: row;
  justify-content: space-between;

  button {
    color: #0e509e;
  }

  small {
    font-weight: initial;
  }


  > div {
    vertical-align: middle;
    display: table-cell;

    &:last-of-type {
      text-align: right;
    }

    &:first-of-type {
      text-align: left;
    }

    p {
      margin: 0;
    }
  }
}

.itemIcon {
  font-size: 40px;
}

.sectionBorder {
  border-top: 2px solid lightgray;
  width: 100%;
  margin: .35em 0 .35em 0
}

.icon {
  font-size: 40px;
  width: 50px;
  margin-right: 10px;
  text-align: center !important;
}

.flexGrow1  {
  flex-grow: 1;
  span {
    position: relative;
    display: inline-block;
    bottom: 10px;
    vertical-align: middle;
  }
}

.flexGrow5 {
  flex-grow: 5;
}

.subscriptionType {
  width: 50%;
}

.settingsTable {
  border-collapse: collapse;
  margin-top: 1rem;
}

.settingsTable th,
.settingsTable td {
  border: none;
  padding: 8px;
  text-align: left;
  @media (max-width: $break-point-sm) {
    padding: 5px 3px;
  }
}
.settingsTable td:nth-child(1) {
  width: 33vw;
  @media (max-width: $break-point-sm) {
    width: unset;
  }
}
.settingsTable tr:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.notificationInfo {
  display: flex;
  align-items: center;
  > span {
    width: 65px;
    flex-shrink: 0;
    font-size: 40px;
    text-align: center;
  }
}

.notificationDetails {
  display: flex;
  flex-direction: column;
}



.row .title {
  text-align: left;
}


.followTable {
}
.followTable td {
  border: none;
  padding: 8px;
  text-align: left;
}
.followTable td:nth-child(1) {
  width: 33vw;
}
.followTable tr:hover {
  background-color: #ddd;
}
.followTable th {
  padding-top: 12px;
  padding-bottom: 12px;
}
.followTable tr:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.displayContainer {
  margin: 2rem $terrace-outer-margin 5rem $terrace-outer-margin;
}

.centerContainer {
  text-align: center;
}

.optionsContainer {
  display: inline-block;
  padding: 30px 50px;
  border-radius: 30px;
  &BackgroundImage {
    background: white;
  }
  @media (max-width: $break-point-sm) {
    background: unset;
    padding: unset;
  }
}

.userDashboardButtons {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;

  div {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem 1.5rem;
    border-radius: 20px;
    font-size: 1.5rem;

    button {
      color: #0e90d2;
      transition: none;

      &:disabled {
        color: $black;
        font-weight: 600;
      }
    }
  }
}

.notificationSettings {
  text-align: center;
  margin-top: 10px;

  > div {
    position: relative;
    display: inline-block;
    text-align: left;
  }
}

.sendTestNotification {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: 600;
  &:disabled {
    opacity: 0.5;
    background: #ccc;
  }
}
.sendTestNotification:hover {
  background: var(--secondaryNavBackground);
  color: var(--secondaryNavBackgroundTextColor);
}
